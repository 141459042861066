import React from "react"
import discordLogo from "./DiscordLogoDark.png"
import "./DiscordArea.css"
import SendIcon from "@mui/icons-material/Send"

function DiscordArea() {
    const discordInviteFade = function () {
        document.querySelector(".discord-send-message-area").style.display = "none"
        document.querySelector(".discord-invite-button").style.display = "block"
        setTimeout(() => {
            document.querySelector(".discord-send-message-area").style.display = "block"
            document.querySelector(".discord-invite-button").style.display = "none"
        }, 10000)
    }

    const Discord_online = function () {
        fetch("https://discord.com/api/guilds/707350118831226951/widget.json")
            .then((response) => response.json())
            .then((data) => {
                let x = data.presence_count
                document.querySelector(".discord-area-member").innerHTML = x + " Aktif Üye"
            })
    }

    return (
        <div className="discord">
            <div className="discord-area-header">
                <img src={discordLogo} alt="görsel yüklenemedi" className="discord-header-img" />
            </div>
            <div className="discord-area-info">
                <Discord_online />
                <div className="discord-area-channel">#📻・radyo-chat</div>
                <div className="discord-area-member">10.000 üye</div>
            </div>
            <div className="discord-message-area"></div>
            <div className="discord-invite-area">
                <div className="discord-send-message-area">
                    <div className="discord-send-message" onClick={discordInviteFade}>
                        <div className="discord-send-message-txt">Mesaj gönder</div>
                        <SendIcon fontSize="small" />
                    </div>
                </div>
                <div className="discord-invite-button">
                    <a href="https://discord.gg/yazilimcimekani" target="_blank" rel="noopener noreferrer">
                        <div className="dc-invite-btn">Sunucuya Katıl</div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DiscordArea
