import React from "react"
import LiveRadio from "./LiveRadio"
import "./LiveRadioArea.css"

function LiveRadioArea() {
    // let lastkey_eski,lastkey_yeni;

    // let eski_veri = "Ağustosa aaaaaaaaaaaaaaaaaaaaaaaaaaaaa";
    // var uzunluk = eski_veri.length;
    // if(uzunluk>=10){
    //   let part_eski = eski_veri.slice(0,9);
    //   lastkey_eski = part_eski + "...";
    // }
    // else{
    //   lastkey_eski = eski_veri;
    // }

    // let yeni_veri = "Ağustos Sırılsıklamaaaaaaaaaa";
    // var uzunluk_1 = yeni_veri.length;
    // if(uzunluk_1>=10){
    //   let part_yeni = yeni_veri.slice(0,10);
    //   lastkey_yeni = part_yeni + "...";
    // }
    // else{
    //   lastkey_yeni = eski_veri;
    // }

    return (
        <div className="live-radio-area">
            <LiveRadio className="nowPlay" />

            <a href="#general-body">
                <div className="scroll-down"></div>
            </a>
        </div>
    )
}

export default LiveRadioArea
