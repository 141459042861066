import React from "react"
import "./LiveRadio.css"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import PlayCircleIcon from "@mui/icons-material/PlayCircle"
import PauseCircleIcon from "@mui/icons-material/PauseCircle"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
// import ArticleIcon from '@mui/icons-material/Article';
import { useEffect, useState } from "react"

function LiveRadio() {
    var degisken = 0
    useEffect(() => {
        degisken = localStorage.getItem("Ses") == undefined ? 20 : localStorage.getItem("Ses")
        document.querySelector("#rangeValue").innerHTML =
            localStorage.getItem("Ses") == undefined ? "%" + 20 : "%" + localStorage.getItem("Ses")
        document.querySelector("#volumeid").value = localStorage.getItem("Ses") == undefined ? 20 : localStorage.getItem("Ses")
    }, [])

    window.addEventListener("scroll", () => {
        // const generalBody=document.querySelector(".general-radio-area");
        // const generalBodyCoord=generalBody.getBoundingClientRect();
        //console.log(generalBodyCoord.y);
        // if(window.scrollY>generalBodyCoord.y){
        //   document.querySelector(".bottom-player-con").style.display="block" ;
        // }else if(document.querySelector(".lyrics-area").style.display==="block"){
        //   document.querySelector(".bottom-player-con").style.display="block" ;
        // }else{
        //   document.querySelector(".bottom-player-con").style.display="none" ;
        // }
    })
    const progressToggleOn = function () {
        document.querySelector(".progress-toggle").style.display = "block"
        document.querySelector(".progress-toggle").style.left = document.querySelector(".progress-now").offsetWidth - 20 + "px"
    }
    const progressToggleOff = function () {
        document.querySelector(".progress-toggle").style.display = "none"
    }
    // const lyricsOpen=function(){
    //   document.querySelector(".live-radio").style.display="none";
    //   document.querySelector(".lyrics-area").style.display="block";
    //   // document.querySelector(".bottom-player-con").style.display="block";
    // }
    const lyricsClose = function () {
        document.querySelector(".live-radio").style.display = "flex"
        document.querySelector(".lyrics-area").style.display = "none"
        // document.querySelector(".bottom-player-con").style.display="none";
    }
    const Sanatcigetir = function () {
        fetch("https://radio.yazilimcimekani.com/api/nowplaying")
            .then((response) => response.json())
            .then((data) => {
                let x = data[0]
                document.querySelector(".name-artist").innerHTML = x.now_playing.song.artist
            })
    }
    const Sarkigetir = function () {
        fetch("https://radio.yazilimcimekani.com/api/nowplaying")
            .then((response) => response.json())
            .then((data) => {
                let x = data[0]
                if (x.now_playing.song.title.length < 20) {
                    document.querySelector(".name-music").innerHTML = x.now_playing.song.title
                } else {
                    document.querySelector(".kayan-yazi").innerHTML = x.now_playing.song.title
                }
            })
    }

    const Yazigetir = function () {
        setInterval(function () {
            let chatbox = document.querySelector(".discord-message-area")
            fetch("https://vekilhac.com/api/radyochat")
                .then((response) => response.json())
                .then((data) => {
                    let x = data
                    let sonmesaj = x[x.length - 1].author + x[x.length - 1].message
                    if (chatbox.children.length > 0) {
                        if (sonmesaj == chatbox.children[chatbox.children.length - 1].innerText) {
                            return
                        }
                    }
                    chatbox.innerHTML = ""

                    function metin_uzun(i) {
                        if (data[i].message.length > 100) {
                            var yeni_metin = data[i].message.slice(0, 100)
                            var yeni_metin_1 = yeni_metin + "<b style='font-size:20px;'> ...</b>"
                            return yeni_metin_1
                        } else {
                            return data[i].message
                        }
                    }

                    for (let i = 0; i < x.length; i++) {
                        chatbox.innerHTML += `<div class="dc-msg"><span class="username" style="color:${data[i].color} ">${
                            data[i].author
                        }
            </span>${metin_uzun(i)}</div>
            `
                    }
                    chatbox.scrollTop = chatbox.scrollHeight
                })
        }, 2000)
    }

    const playbutton = function () {
        document.querySelector(".playIcon").style.display = "none"
        document.querySelector(".stopIcon").style.display = "block"
        document.querySelector("#radyo").src = "https://radio.yazilimcimekani.com/radio/8000/radio.mp3"
        document.querySelector("#radyo").play()
        document.querySelector("#radyo").volume = degisken / 100
    }

    const stopbutton = function () {
        document.querySelector(".playIcon").style.display = "block"
        document.querySelector(".stopIcon").style.display = "none"
        document.querySelector("#radyo").pause()
    }

    const volumeval = (e) => {
        document.querySelector("#radyo").volume = e.target.value / 100
        degisken = e.target.value
        document.querySelector("#rangeValue").innerHTML = "%" + degisken
        document.querySelector("#volumeid").value = degisken
        localStorage.setItem("Ses", degisken)
    }
    function muteval() {
        if (degisken == 0) {
            document.querySelector("#radyo").volume = parseInt(localStorage.getItem("Ses")) / 100
            degisken = localStorage.getItem("Ses")
            document.querySelector("#volumeid").value = degisken
            document.querySelector("#rangeValue").innerHTML = "%" + degisken
        } else if (degisken > 0) {
            localStorage.setItem("Ses", parseInt(degisken))
            document.querySelector("#radyo").volume = 0 / 100
            degisken = 0
            document.querySelector("#volumeid").value = degisken
            document.querySelector("#rangeValue").innerHTML = "%" + degisken
        }
    }

    function Apireq() {
        fetch("https://radio.yazilimcimekani.com/api/nowplaying")
            .then((response) => response.json())
            .then((data) => {
                let x_1 = data[0].song_history[0].song.artist
                let y_1 = data[0].song_history[0].song.title
                let x_2 = data[0].song_history[1].song.artist
                let y_2 = data[0].song_history[1].song.title
                let x_3 = data[0].song_history[2].song.artist
                let y_3 = data[0].song_history[2].song.title
                let x_4 = data[0].song_history[3].song.artist
                let y_4 = data[0].song_history[3].song.title
                let x_5 = data[0].song_history[4].song.artist
                let y_5 = data[0].song_history[4].song.title
                let res1 = data[0].song_history[0].song.art
                let res2 = data[0].song_history[1].song.art
                let res3 = data[0].song_history[2].song.art
                let res4 = data[0].song_history[3].song.art
                let res5 = data[0].song_history[4].song.art

                document.querySelector(".res1").src = res1
                document.querySelector(".res2").src = res2
                document.querySelector(".res3").src = res3
                document.querySelector(".res4").src = res4
                document.querySelector(".res5").src = res5
                document.querySelector(".last-track-artist-1").innerHTML = x_1
                document.querySelector(".last-track-music-1").innerHTML = y_1
                document.querySelector(".last-track-artist-2").innerHTML = x_2
                document.querySelector(".last-track-music-2").innerHTML = y_2
                document.querySelector(".last-track-artist-3").innerHTML = x_3
                document.querySelector(".last-track-music-3").innerHTML = y_3
                document.querySelector(".last-track-artist-4").innerHTML = x_4
                document.querySelector(".last-track-music-4").innerHTML = y_4
                document.querySelector(".last-track-artist-5").innerHTML = x_5
                document.querySelector(".last-track-music-5").innerHTML = y_5

                let z_1 = data[0].song_history[0].played_at
                var guncel = Math.floor(Date.now() / 1000) - z_1
                var z_1_1 = Math.floor(guncel / 60) + " dk"
                document.querySelector(".last-track-time-1").innerHTML = z_1_1

                let z_2 = data[0].song_history[1].played_at
                guncel = Math.floor(Date.now() / 1000) - z_2
                var z_2_1 = Math.floor(guncel / 60) + " dk"
                document.querySelector(".last-track-time-2").innerHTML = z_2_1

                let z_3 = data[0].song_history[2].played_at
                guncel = Math.floor(Date.now() / 1000) - z_3
                var z_3_1 = Math.floor(guncel / 60) + " dk"
                document.querySelector(".last-track-time-3").innerHTML = z_3_1

                let z_4 = data[0].song_history[3].played_at
                guncel = Math.floor(Date.now() / 1000) - z_4
                var z_4_1 = Math.floor(guncel / 60) + " dk"
                document.querySelector(".last-track-time-4").innerHTML = z_4_1

                let z_5 = data[0].song_history[4].played_at
                guncel = Math.floor(Date.now() / 1000) - z_5
                var z_5_1 = Math.floor(guncel / 60) + " dk"
                document.querySelector(".last-track-time-5").innerHTML = z_5_1
            })
    }

    var live_durum = 0
    let son_durum = 0

    const Livegetir = function () {
        setInterval(() => {
            fetch("https://radio.yazilimcimekani.com/api/nowplaying")
                .then((response) => response.json())
                .then((data) => {
                    let x = data[0]
                    if (x.live.is_live == true) {
                        live_durum = 1
                    } else {
                        live_durum = 0
                    }
                    if (live_durum == 0) {
                        document.querySelector(".live-now").style.display = "none"
                        document.querySelector(".max-text").style.display = "flex"
                    } else if (live_durum == 1) {
                        document.querySelector(".live-now").style.display = "flex"
                        document.querySelector(".max-text").style.display = "none"
                    }
                })
        }, 2000)
    }

    const Kalangetir = function () {
        fetch("https://radio.yazilimcimekani.com/api/nowplaying")
            .then((response) => response.json())
            .then((data) => {
                let x = data[0]
                let baslangic = x.now_playing.played_at
                let now = Math.round(new Date().getTime() / 1000)
                let sure = now - baslangic
                let sarkiuzunluk = x.now_playing.duration

                let res1 = data[0].now_playing.song.art
                document.querySelector(".music-photo-img").src = res1
                Livegetir()
                function fmtMSS(s) {
                    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s
                }

                let surekalani = fmtMSS(sure)
                let sarki_max_time = fmtMSS(sarkiuzunluk)
                document.querySelector(".max-text").innerHTML = sarki_max_time
                var sonuc = ((sure / sarkiuzunluk) * 100).toFixed(2) // yüzde hesaplama
                let eksisayi2 = 1
                let barupdate = setInterval(function () {
                    // console.log(eksisayi2);
                    let suree = sure + eksisayi2
                    var barup = (suree / sarkiuzunluk) * 100
                    barup = barup.toFixed(2)
                    document.querySelector(".progress-now").style.width = barup + "%"
                    if (barup > 100) {
                        clearInterval(barupdate)
                    }
                    eksisayi2++
                }, 1000)

                if (x.now_playing.song.title.length < 20) {
                    document.querySelector(".name-music").innerHTML = x.now_playing.song.title
                } else {
                    document.querySelector(".kayan-yazi").innerHTML = x.now_playing.song.title
                }

                if (x.live.is_live == true) {
                    live_durum = 1
                } else {
                    live_durum = 0
                }
                if (live_durum == 0) {
                    document.querySelector(".live-now").style.display = "none"
                    document.querySelector(".max-text").style.display = "flex"
                } else if (live_durum == 1) {
                    document.querySelector(".live-now").style.display = "flex"
                    document.querySelector(".max-text").style.display = "none"
                }
                // setInterval(function () {
                //   if(live_durum == 0){
                //     if(son_durum == 1){
                //       son_durum = 0;
                //       Kalangetir();
                //     }
                //   }else if(live_durum == 1){
                //     if(son_durum == 0){
                //       son_durum = 1;

                //       Kalangetir();
                //     }
                //   }
                // }, 1000);

                document.querySelector(".name-artist").innerHTML = x.now_playing.song.artist
                document.querySelector(".progress-now").style.width = sonuc + "%"
                document.querySelector(".progress-toggle").innerHTML = surekalani
                let eksisayi = 0

                if (sarki_max_time != "0:00") {
                    let sureyenile = setInterval(function () {
                        if (sure + eksisayi >= sarkiuzunluk) {
                            clearInterval(sureyenile)
                            document.querySelector(".progress-now").style.width = "0%"
                            document.querySelector(".progress-toggle").innerHTML = "0:00"
                            Kalangetir()
                            Apireq()
                        }
                        let surekalanii = fmtMSS(sure + eksisayi)
                        document.querySelector(".progress-toggle").innerHTML = surekalanii
                        eksisayi++
                    }, 1000)
                }
            })
        // let x = data[0];
        // let baslangic = x.now_playing.played_at;
        // let now = Math.round(new Date().getTime()/1000);
        // let sure = now - baslangic;
        // let sarkiuzunluk = x.now_playing.duration;

        // function fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

        // let sarki_max_time = fmtMSS(sarkiuzunluk);

        // document.querySelector(".max-text").innerHTML=sarki_max_time;

        // let surekalani = fmtMSS(sure);
        // var sonuc=(sure/sarkiuzunluk)*100;
        // sonuc=sonuc.toFixed(2);
        // let eksisayi2 = 1;
        // let barupdate = setInterval(function(){
        //   let suree = sure+eksisayi2;
        //   var barup=(suree/sarkiuzunluk)*100;
        //   barup=barup.toFixed(2);

        //   document.querySelector(".progress-now").style.width=barup+"%";
        //   if(barup > 100 ){
        //     clearInterval(barupdate);
        //   }
        //   console.log(suree);
        //   eksisayi2++;

        // },1000);

        // document.querySelector(".name-music").innerHTML=x.now_playing.song.title;
        // document.querySelector(".name-artist").innerHTML=x.now_playing.song.artist;
        // document.querySelector(".progress-now").style.width=sonuc+"%";
        // document.querySelector(".progress-toggle").innerHTML=surekalani;
        // let eksisayi = 0;
        // let sureyenile = setInterval(function() {
        //   if(sure+eksisayi>=sarkiuzunluk){
        //     clearInterval(sureyenile);
        //     document.querySelector(".progress-now").style.width="0%";
        //     document.querySelector(".progress-toggle").innerHTML="0:00";
        //     Kalangetir();

        //   }
        //   let surekalanii = fmtMSS(sure+eksisayi);
        //   document.querySelector(".progress-toggle").innerHTML=surekalanii;
        //   eksisayi++;
        // }, 1000);
        // })
    }

    /*
  const textSliderLeft=function(){
    let artist_name=document.querySelector(".name-artist").innerHTML.length*18;
    let music_name=document.querySelector(".name-music").innerHTML.length*12;
    let progress_bar=document.querySelector(".progress-bar").offsetWidth;
    if(music_name>progress_bar){
      let music_name_name=document.querySelector(".name-music").innerHTML;
      document.querySelector(".name-music").innerHTML=`<marquee loop>${music_name_name}</marquee>`
     
    }
    if(artist_name>progress_bar){
      let artist_name_name=document.querySelector(".name-artist").innerHTML;
      document.querySelector(".name-artist").innerHTML=`<marquee loop>${artist_name_name}</marquee>`
     
    }
    
  }
  
  const textSliderStop=function(){
    let artist_name=document.querySelector(".name-artist").innerHTML.length*18;
    let music_name=document.querySelector(".name-music").innerHTML.length*12;
    let progress_bar=document.querySelector(".progress-bar").offsetWidth;
    if(music_name>progress_bar){
      let music_name_name=document.querySelector(".name-music").innerHTML;
      document.querySelector(".name-music").innerHTML=music_name_name;
     
    }
    if(artist_name>progress_bar){
      let artist_name_name=document.querySelector(".name-artist").innerHTML;
      document.querySelector(".name-artist").innerHTML=artist_name_name;
     
    }
    
  }*/

    return (
        <div className="live-radio-container">
            <div className="lyrics-area">
                <div className="close-lyrics-area" onClick={lyricsClose}>
                    <KeyboardArrowDownIcon className="close-lyrics-area-icon" />
                    <span className="lyrics-header">Şarkı Sözleri</span>
                </div>
                <div className="lyrics-area-text">
                    Bildiği gibi değilmiş buralar, o her şeyi mor severmiş. Kim girse hayatına "seni seviyorum" der ve gidermiş.
                    Kalbi kırılmış bir kadın, belki de saçları ondan kısaymış. Konuşulacak şeyler varmış daha, ama avazı çoktan
                    çıkmış. İşte bu yüzden leş gibidir buralar, İyi ve güzel kadınlar hep ağlar. Zor be kadın ama sen dur bakalım,
                    Yakında yeni bir gezegen bulunur nasıl olsa. İnce ince doğranmış herkese bi parça dağılmış. Sıradan hayalleri
                    varmış ama hepsi ondan alınmış. Kalbi kırılmış bir kadın, Ve hiç zamanı olmamış alışmaya. Yaşamak bir
                    meslektir buralarda, Zaten inancı kalmamış mutlu sonlara. İşte bu yüzden leş gibidir buralar, İyi ve güzel
                    kadınlar hep ağlar. Zor be kadın ama sen dur bakalım, Yakında yeni bir gezegen bulunur nasıl olsa. İşte bu
                    yüzden leş gibidir buralar, İyi ve güzel kadınlar hep ağlar. Zor be kadın ama sen dur bakalım, Yakında yeni
                    bir gezegen bulunur nasıl olsa. Bildiği gibi değilmiş buralar, o her şeyi mor severmiş. Kim girse hayatına
                    "seni seviyorum" der ve gidermiş. Kalbi kırılmış bir kadın, belki de saçları ondan kısaymış. Konuşulacak
                    şeyler varmış daha, ama avazı çoktan çıkmış. İşte bu yüzden leş gibidir buralar, İyi ve güzel kadınlar hep
                    ağlar. Zor be kadın ama sen dur bakalım, Yakında yeni bir gezegen bulunur nasıl olsa. İnce ince doğranmış
                    herkese bi parça dağılmış. Sıradan hayalleri varmış ama hepsi ondan alınmış. Kalbi kırılmış bir kadın, Ve hiç
                    zamanı olmamış alışmaya. Yaşamak bir meslektir buralarda, Zaten inancı kalmamış mutlu sonlara. İşte bu yüzden
                    leş gibidir buralar, İyi ve güzel kadınlar hep ağlar. Zor be kadın ama sen dur bakalım, Yakında yeni bir
                    gezegen bulunur nasıl olsa. İşte bu yüzden leş gibidir buralar, İyi ve güzel kadınlar hep ağlar. Zor be kadın
                    ama sen dur bakalım, Yakında yeni bir gezegen bulunur nasıl olsa.
                </div>
            </div>
            <div className="live-radio-area">
                <div className="live-radio">
                    <div className="music-photo">
                        <a href="#">
                            <img className="music-photo-img" alt="Görsel Bulunmamaktadır" />
                        </a>
                    </div>
                    <div className="music-description">
                        <div>
                            <div className="music-status">
                                <Yazigetir />
                            </div>
                            <div className="name-music">
                                <marquee className="kayan-yazi">
                                    <Sarkigetir />
                                </marquee>
                            </div>
                            <div className="name-artist">
                                <Sanatcigetir />
                            </div>
                        </div>
                        <div className="progress-bar" onMouseMove={progressToggleOn} onMouseLeave={progressToggleOff}>
                            <div className="progress-back">
                                <div
                                    className="progress-now"
                                    onMouseMove={progressToggleOn}
                                    onMouseLeave={progressToggleOff}
                                ></div>
                                <div className="progress-toggle">
                                    <Kalangetir />
                                </div>
                                <div className="max-text" style={{ fontSize: "medium" }}></div>
                            </div>

                            {/* CANLI YAYINA GİRDİĞİNDE BU ÇIKACAK ŞEKİLDE AYARLAYACAZ */}

                            <div className="live-now">
                                <div className="live-bound"></div>
                                <div className="live-txt">live</div>
                            </div>
                        </div>
                        <div className="player-buttons">
                            {/*<div className='lyrics-button' >
                <ArticleIcon className='lyricsIcon' />
                 onClick={lyricsOpen}  --> yukarının onclick eventi
              </div>*/}

                            <div className="play-button">
                                <PlayCircleIcon className="playIcon" onClick={playbutton} />
                                <PauseCircleIcon className="stopIcon" style={{ display: "none" }} onClick={stopbutton} />
                                <audio id="radyo" src="https://radio.yazilimcimekani.com/radio/8000/radio.mp3"></audio>
                            </div>
                        </div>
                        <div className="voice-button">
                            <VolumeUpIcon className="voiceIcon" onClick={muteval} />
                            <span id="rangeValue"></span>
                            <div className="progvolume">
                                <input
                                    className="volumevalue"
                                    id="volumeid"
                                    type="range"
                                    min="0"
                                    max="100"
                                    onChange={volumeval}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveRadio
