import React from "react"

function LastTrack() {
    function Apireq() {
        fetch("https://radio.yazilimcimekani.com/api/nowplaying")
            .then((response) => response.json())
            .then((data) => {
                let x_1 = data[0].song_history[0].song.artist
                let y_1 = data[0].song_history[0].song.title
                let x_2 = data[0].song_history[1].song.artist
                let y_2 = data[0].song_history[1].song.title
                let x_3 = data[0].song_history[2].song.artist
                let y_3 = data[0].song_history[2].song.title
                let x_4 = data[0].song_history[3].song.artist
                let y_4 = data[0].song_history[3].song.title
                let x_5 = data[0].song_history[4].song.artist
                let y_5 = data[0].song_history[4].song.title
                let res1 = data[0].song_history[0].song.art
                let res2 = data[0].song_history[1].song.art
                let res3 = data[0].song_history[2].song.art
                let res4 = data[0].song_history[3].song.art
                let res5 = data[0].song_history[4].song.art

                document.querySelector(".res1").src = res1
                document.querySelector(".res2").src = res2
                document.querySelector(".res3").src = res3
                document.querySelector(".res4").src = res4
                document.querySelector(".res5").src = res5

                document.querySelector(".last-track-artist-1").innerHTML = x_1
                document.querySelector(".last-track-music-1").innerHTML = y_1
                document.querySelector(".last-track-artist-2").innerHTML = x_2
                document.querySelector(".last-track-music-2").innerHTML = y_2
                document.querySelector(".last-track-artist-3").innerHTML = x_3
                document.querySelector(".last-track-music-3").innerHTML = y_3
                document.querySelector(".last-track-artist-4").innerHTML = x_4
                document.querySelector(".last-track-music-4").innerHTML = y_4
                document.querySelector(".last-track-artist-5").innerHTML = x_5
                document.querySelector(".last-track-music-5").innerHTML = y_5

                let z_1 = data[0].song_history[0].played_at
                var guncel_1 = Math.floor(Date.now() / 1000) - z_1
                var z_1_1 = Math.floor(guncel_1 / 60) + " dk"
                document.querySelector(".last-track-time-1").innerHTML = z_1_1

                let z_2 = data[0].song_history[1].played_at
                var guncel_2 = Math.floor(Date.now() / 1000) - z_2
                var z_2_1 = Math.floor(guncel_2 / 60) + " dk"
                document.querySelector(".last-track-time-2").innerHTML = z_2_1

                let z_3 = data[0].song_history[2].played_at
                var guncel_3 = Math.floor(Date.now() / 1000) - z_3
                var z_3_1 = Math.floor(guncel_3 / 60) + " dk"
                document.querySelector(".last-track-time-3").innerHTML = z_3_1

                let z_4 = data[0].song_history[3].played_at
                var guncel_4 = Math.floor(Date.now() / 1000) - z_4
                var z_4_1 = Math.floor(guncel_4 / 60) + " dk"
                document.querySelector(".last-track-time-4").innerHTML = z_4_1

                let z_5 = data[0].song_history[4].played_at
                var guncel_5 = Math.floor(Date.now() / 1000) - z_5
                var z_5_1 = Math.floor(guncel_5 / 60) + " dk"
                document.querySelector(".last-track-time-5").innerHTML = z_5_1
            })
    }

    return (
        <>
            <div className="last-track">
                <Apireq />
                <div className="last-track-order">1</div>
                <div className="last-track-img">
                    <a href="https://discord.gg/d8WpuMdu">
                        <img className="res1" alt="görsel yüklenemedi" />
                    </a>
                </div>
                <div className="last-track-detail">
                    <div className="last-track-artist-1"></div>
                    <div className="last-track-music-1"></div>
                </div>
                <div className="last-track-time-1">12 dk</div>
            </div>

            <div className="last-track">
                <div className="last-track-order">2</div>
                <div className="last-track-img">
                    <a href="https://discord.gg/d8WpuMdu">
                        <img className="res2" alt="görsel yüklenemedi" />
                    </a>
                </div>
                <div className="last-track-detail">
                    <div className="last-track-artist-2"></div>
                    <div className="last-track-music-2"></div>
                </div>
                <div className="last-track-time-2">12 dk</div>
            </div>

            <div className="last-track">
                <div className="last-track-order">3</div>
                <div className="last-track-img">
                    <a href="https://discord.gg/d8WpuMdu">
                        <img className="res3" alt="görsel yüklenemedi" />
                    </a>
                </div>
                <div className="last-track-detail">
                    <div className="last-track-artist-3"></div>
                    <div className="last-track-music-3"></div>
                </div>
                <div className="last-track-time-3">12 dk</div>
            </div>

            <div className="last-track">
                <div className="last-track-order">4</div>
                <div className="last-track-img">
                    <a href="https://discord.gg/d8WpuMdu">
                        <img className="res4" alt="görsel yüklenemedi" />
                    </a>
                </div>
                <div className="last-track-detail">
                    <div className="last-track-artist-4"></div>
                    <div className="last-track-music-4"></div>
                </div>
                <div className="last-track-time-4">12 dk</div>
            </div>

            <div className="last-track">
                <div className="last-track-order">5</div>
                <div className="last-track-img">
                    <a href="https://discord.gg/d8WpuMdu">
                        <img className="res5" alt="görsel yüklenemedi" />
                    </a>
                </div>
                <div className="last-track-detail">
                    <div className="last-track-artist-5"></div>
                    <div className="last-track-music-5"></div>
                </div>
                <div className="last-track-time-5">12 dk</div>
            </div>
        </>
    )
}
export default LastTrack
