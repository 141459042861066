import React, { Component } from "react";
import "./GeneralRadioArea.css"
import datatablecss from "./datatable.css"
import Swal from 'sweetalert2'

const $ = require("jquery");
$.DataTable = require("datatables.net");

class Sarkilar extends Component {
    componentDidMount() {
        fetch('https://vekilhac.com/api/sarkilar')
            .then(response => response.json())
            .then(json => {
               var table = $('#sarkiistek').DataTable({
                    "data": json,
                    "columns": [
                        { "data": "text" },
                        { "data": "istekbuton" },
                    ],
                    "pageLength": 6,
                    "ordering": false,
                    language: {
                        emptyTable: "Gösterilecek veri yok.",
                        processing: "Veriler yükleniyor",
                        sDecimal: ".",
                        sInfo: "_TOTAL_ kayıttan _START_ - _END_ arası kayıtlar gösteriliyor",
                        sInfoFiltered: "(_MAX_ kayıt içerisinden bulunan)",
                        sInfoPostFix: "",
                        sInfoThousands: ".",
                        sLengthMenu: "Sayfada _MENU_ kayıt göster",
                        sLoadingRecords: "Yükleniyor...",
                        sSearch: "",
                        searchPlaceholder: "Şarkı ara...",
                        sZeroRecords: "Eşleşen kayıt bulunamadı",
                        oPaginate: {
                          sFirst: "İlk",
                          sLast: "Son",
                          sNext: `>`,
                          sPrevious: "<",
                        },
                        oAria: {
                          sSortAscending: ": artan sütun sıralamasını aktifleştir",
                          sSortDescending: ": azalan sütun sıralamasını aktifleştir",
                        },
                        select: {
                          rows: {
                            _: "%d kayıt seçildi",
                            0: "",
                            1: "1 kayıt seçildi",
                          },
                        },
                        },
                });
                table.on('click', '.track-request', function (e) {
                  fetch(`https://radio.yazilimcimekani.com/api/station/1/request/${e.target.value}`, {
                    method: 'POST'
                  })
                  .then(response => response.json())
                  .then(json => {
                    if (json.success == true) {
                      Swal.fire({
                        icon: 'success',
                        title: 'İstek gönderildi',
                        showConfirmButton: false,
                        timer: 1500
                      });
                    } else if(json.success == false) {
                      if(json.message.startsWith("Çok yakın")) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Bir önceki isteğinizden çok yakın bir zamanda istek gönderemezsiniz.',
                          showConfirmButton: false,
                          timer: 2000
                        });
                      }else if(json.message.startsWith("Yinelenen İstek") || json.message.startsWith("Bu şarkı")) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Bu şarkı yakın bir zamanda çalındı.',
                          showConfirmButton: false,
                          timer: 2000
                        });
                      }
                    }
                  });
                });
            });
    }
    render(){
    return ( 
            <table id="sarkiistek" className="display">
              <tbody>
              </tbody>
          </table>
    );
  }
  } 



function TrackList() {
    return (
        <div className="track-list-con">
            <Sarkilar />
        </div>
    )
}

export default TrackList
